/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { useGetCaseHistoryListQuery } from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { followUpTypes } from 'helpers/readConfig'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Collapse,
} from '@mui/material'
import {
  CircleRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CaseFollowUpFilters from './CaseFollowUpComponents/CaseFollowUpFilters'
import CaseFollowUpHistoryCard from './CaseFollowUpComponents/CaseFollowUpHistoryCard'

/* Type imports ------------------------------------------------------------- */
import type {
  CodeLabel,
  SuiviSimplifie,
  TypeSuivi,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface FollowUpFilters {
  eventType: TypeSuivi | '';
  recipient: string;
  withDocuments: string;
}

type HistoryGroup = {[x: string]: SuiviSimplifie[]}

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    flex-direction: column;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

interface GroupContainerProps {
  last: boolean;
}

const GroupContainer = styled.div<GroupContainerProps>`
  border-left: 2px solid ${(props) => props.theme.colors.grey};
  padding-left: 20px;
  padding-bottom: ${(props) => props.last ? '0px' : '20px'};
  margin-left: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: 20px;
    border: none;
    padding-left: 0px;
    margin-left: 0px;
  }
`

const DotContainer = styled.div`
  margin-left: -31px;
  margin-right: 13px;
  margin-top: 2px;
`

const DateContainer = styled.div`
  display: flex;
  position: relative;
  top: -3px;
  align-items: start;
`

interface CardContainerProps {
  last: boolean;
}

const CardContainer = styled.div<CardContainerProps>`
  margin-bottom: ${(props) => props.last ? '0px' : '10px'};
`

/* Component declaration ---------------------------------------------------- */
interface CaseFollowUpPageProps {}

const CaseFollowUpPage: React.FC<CaseFollowUpPageProps> = () => {
  const { caseId } = useParams<{ caseId: string }>()
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)
  const [ filteredHistoryList, setFilteredHistoryList ] = useState<SuiviSimplifie[]>([])
  const [ filters, setFilters ] = useState<FollowUpFilters>({ eventType: '', recipient: '', withDocuments: '' })
  const [ eventTypeList, setEventTypeList ] = useState<CodeLabel[]>([])
  const [ recipientList, setRecipientList ] = useState<string[]>([])

  const {
    currentData: caseHistory = [],
    isFetching: isFetchingCaseHistory,
  } = useGetCaseHistoryListQuery(caseId || '')

  useEffect(() => {
    if (!isFetchingCaseHistory) {
      setFilteredHistoryList(caseHistory)
      setFilters({ eventType: '', recipient: '', withDocuments: '' })
      setEventTypeList(
        [ ...new Map(caseHistory.map((history) => history.type).map((v) => [ v.code, v ])).values() ]
          .sort((a, b) => (a.libelle || '').localeCompare(b.libelle || '')),
      )
      setRecipientList(
        [ ...new Set(caseHistory.flatMap((history) => history.destinataires.map((recipient) => recipient))) ]
          .filter((value) => value !== '')
          .sort((a, b) => a.localeCompare(b)),
      )
    }
  }, [ isFetchingCaseHistory ])

  useEffect(() => {
    if (!isFetchingCaseHistory) {
      setFilteredHistoryList(caseHistory.filter((history) =>
        (filters.eventType === '' || history.type.code === filters.eventType) &&
        (filters.recipient === '' || history.destinataires.some((recipient) => recipient === filters.recipient)) &&
        (filters.withDocuments === '' || (filters.withDocuments === 'true' ? history.pieceJointes.length > 0 : history.pieceJointes.length === 0)),
      ))
    }
  }, [ filters, isFetchingCaseHistory ])

  const sortHistory = () => {
    const sortedHistory: SuiviSimplifie[] = [ ...[ ...filteredHistoryList ].filter((history) => followUpTypes.includes(history.type.code)).sort((a, b) => (b.date || '').localeCompare(a.date || '')) ]
    const groups: HistoryGroup = {}

    if (isFetchingCaseHistory) {
      return groups
    }

    const sortHistoryToGroup = (date: string): void => {
      if (!sortedHistory.length) return
      if (!groups[date]?.length) groups[date] = []
      if (DateUtils.APIStrToLocalDateString(date) === DateUtils.APIStrToLocalDateString(sortedHistory[0]?.date)) {
        const toAdd = sortedHistory.shift()
        toAdd && groups[date].push(toAdd)
        sortHistoryToGroup(date)
      }
      else {
        sortHistoryToGroup(sortedHistory[0].date || '')
      }
    }

    sortHistoryToGroup(sortedHistory[0]?.date || '')
    return groups
  }

  const sortedHistory = useMemo(() => sortHistory(), [ filteredHistoryList, isFetchingCaseHistory ])

  const onEraseFilterClick = () => {
    setFilters({ eventType: '', recipient: '', withDocuments: '' })
  }

  return (
    <div>
      <LargeTitle>
        Suivi Dossier
      </LargeTitle>
      <FilterDesktopContainer>
        <CaseFollowUpFilters
          filters={filters}
          setFilters={setFilters}
          eventTypeList={eventTypeList}
          recipientList={recipientList}
          onEraseFilterClick={onEraseFilterClick}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
          Filtres
          <DropDownArrow open={openedFilterMenu} />
        </FormTitleWithArrow>
        <Collapse
          in={openedFilterMenu}
          timeout="auto"
          unmountOnExit
        >
          <CaseFollowUpFilters
            size="small"
            filters={filters}
            setFilters={setFilters}
            eventTypeList={eventTypeList}
            recipientList={recipientList}
            onEraseFilterClick={onEraseFilterClick}
          />
        </Collapse>
        <BoldSeparatorMargin />
      </FilterMobileContainer>
      {isFetchingCaseHistory && <CircularProgress />}
      {
        Object.entries(sortedHistory)
          .sort((a, b) => b[0].localeCompare(a[0]))
          .map(([ date, historyList ], index) => (
            <GroupContainer
              key={`${date}-${index}`}
              last={index === Object.entries(sortedHistory).length - 1}
            >
              <DateContainer>
                <DotContainer>
                  <CircleRounded
                    color="primary"
                    fontSize="small"
                  />
                </DotContainer>
                {DateUtils.APIStrToLocalDateString(date)}
              </DateContainer>
              {
                historyList.map((history, historyIndex) => (
                  <CardContainer
                    key={`${history.id}-${index}-${historyIndex}`}
                    last={historyIndex === historyList.length - 1}
                  >
                    <CaseFollowUpHistoryCard history={history} />
                  </CardContainer>
                ))
              }
            </GroupContainer>
          ))
      }
    </div>
  )
}

export default CaseFollowUpPage
