/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetAgencyListQuery,
  useGetCompanyListQuery,
  useGetDisasterNatureListQuery,
  useGetCaseListQuery,
  useGetCaseDocumentInfosListQuery,
  useGetCaseStateCodeListQuery,
} from 'store/api'
import {
  exportToXLSX,
  getRowsComparator,
} from 'helpers/tableUtils'
import DateUtils from 'helpers/DateUtils'
import { getCaseStatusColor } from 'helpers/caseStatusColor'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'
import { getCaseListPeriod } from 'store/slices/caseListFilterSlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Collapse,
} from '@mui/material'
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import RoundedChip from 'components/RoundedChip/RoundedChip'
import Table from 'components/Table/Table'
import CheckableSortingButton from 'components/CheckableSortingButton/CheckableSortingButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import MyCasesFilters from './MyCasesComponents/MyCasesFilters'
import MyCasesMobileCard from './MyCasesComponents/MyCasesMobileCard'
import MyCasesDocuments from './MyCasesComponents/MyCasesDocuments'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  MesDossiersDossierListParams,
  MesDossierResultat,
  CodeLabel,
} from 'API/__generated__/Api'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'types/Table'

/* Type declarations -------------------------------------------------------- */
const myCasesSchema = Yup.mixed<MesDossiersDossierListParams>().required()

type MyCasesForm = FormikContextType<MesDossiersDossierListParams>

interface OpenedMenu {
  filter: boolean;
  sort: boolean;
}

/* Internal variables ------------------------------------------------------- */
const periodList = [
  {
    value: 0,
    label: 'Tout',
  },
  {
    value: 7,
    label: '7 derniers jours',
  },
  {
    value: 30,
    label: '30 derniers jours',
  },
  {
    value: 93,
    label: '3 derniers mois',
  },
  {
    value: 365,
    label: "L'année passée",
  },
]

const RESULT_LIMIT = 10

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 75% 24%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const Chip = styled(RoundedChip)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const StatusChipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .status-chip {
    font-size: .8rem;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const Link = styled.a`
  color: ${(props) => props.theme.palette.primary.main};
`

/* Component declaration ---------------------------------------------------- */
interface MyCasesPageProps {}

const MyCasesPage: React.FC<MyCasesPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const period = useAppSelector(getCaseListPeriod)
  const [ caseList, setCaseList ] = useState<MesDossierResultat[]>([])
  const [ openedMenu, setOpenedMenu ] = useState<OpenedMenu>({ filter: false, sort: false })
  const [ statusFilters, setStatusFilters ] = useState<{status: CodeLabel; amount: number | null}[]>([])
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ order, setOrder ] = useState<Order>('asc')
  const [ orderBy, setOrderBy ] = useState<DataName>('')

  const formikForm: MyCasesForm = useForm<MesDossiersDossierListParams>(
    {
      initialValues: {
        Limit: RESULT_LIMIT,
        StartIndex: 0,
        Agence: '',
        Compagnie: '',
        NatureSinistre: '',
        NombreDeJour: period,
        CodesEtatsDossier: [],
      },
      validationSchema: myCasesSchema,
    },
  )

  const {
    currentData: companyList = [],
    isFetching: isFetchingCompanyList,
  } = useGetCompanyListQuery()
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterNatureListQuery()
  const {
    currentData: caseStateList = [],
    isFetching: isFetchingCaseStateList,
  } = useGetCaseStateCodeListQuery()
  const {
    currentData: agencyList = [],
    isFetching: isFetchingAgencyList,
  } = useGetAgencyListQuery({ cabinet: '' })
  const {
    currentData: caseResultList,
    isFetching: isFetchingCaseResultList,
  } = useGetCaseListQuery(formikForm.values)
  const {
    currentData: documentInfosList = [],
    isFetching: isFetchingDocumentInfosList,
  } = useGetCaseDocumentInfosListQuery(
    { dossiers: caseResultList?.resultats?.map((cases) => cases.id) },
    { skip: caseResultList?.resultats?.length === 0 || !caseResultList?.resultats },
  )

  const saveLastPath = () => {
    dispatch(setRouterLastPath(location.pathname))
  }

  const handleNavigate = (caseId: string) => {
    saveLastPath()
    navigate(caseId)
  }

  useEffect(() => {
    if (caseResultList?.resultats?.length !== undefined) {
      setCaseList(caseResultList?.resultats?.slice().sort(getRowsComparator(order, orderBy)) ?? [])
    }
    if (caseResultList?.totalResultats !== undefined && caseResultList.totalResultats !== totalRows) {
      setTotalRows(caseResultList.totalResultats)
    }
    if (caseResultList?.resultatsParCodeEtatDossier) {
      setStatusFilters(
        Object.entries(caseResultList?.resultatsParCodeEtatDossier).map(([ code, amount ]) => ({
          status: caseStateList.find((state) => state.code?.code === code)?.code ?? { code: '', libelle: '' },
          amount,
        })),
      )
    }
  }, [ isFetchingCaseStateList ])

  const cols: ColumnHeader[] = [
    {
      id: 'sinistre.referenceCompagnie',
      label: 'Ref compagnie',
      render: (companyRef: string, row) => (
        <Link
          href={`/dossiers/${(row as MesDossierResultat).id}`}
          onClick={(e) => {e.stopPropagation(); saveLastPath()}}
        >
          {companyRef}
        </Link>
      ),
    },
    {
      id: 'id',
      label: 'Ref cabinet',
      render: (id: string) => (
        <Link
          href={`/dossiers/${id}`}
          onClick={(e) => {e.stopPropagation(); saveLastPath()}}
        >
          {id.split('-')[id.split('-').length - 1]}
        </Link>
      ),
    },
    {
      id: 'compagnie.libelle',
      label: 'Compagnie',
    },
    {
      id: 'assure.nom',
      label: 'Assuré',
    },
    {
      id: 'assure.police',
      label: 'N° de Contrat',
    },
    {
      id: 'agenceTraitante',
      label: 'Agence Traitante',
    },
    {
      id: 'sinistre.nature.libelle',
      label: 'Type de sinistre',
    },
    {
      id: 'sinistre.date',
      label: 'Date sinistre',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date),
    },
    {
      id: 'dateDepotRapport',
      label: 'Date dépot rapport',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date),
    },
    {
      id: 'etat',
      label: 'Statut',
      sortId: 'etat.code',
      render: (status: CodeLabel) => (
        <StatusChipContainer>
          <ColoredSquareChip
            color={getCaseStatusColor(status.code)}
            className="status-chip"
          >
            {status.libelle}
          </ColoredSquareChip>
        </StatusChipContainer>
      ),
      renderForExport: (status: CodeLabel) => status.libelle || '',
    },
    {
      id: 'id',
      label: 'PJ',
      removeFromExport: true,
      render: (id: string, row) => (
        <MyCasesDocuments
          documentsInfo={documentInfosList.find((documentInfo) => documentInfo.dossier === id)}
          caseId={(row as MesDossierResultat).id}
          isFetching={isFetchingDocumentInfosList}
        />
      ),
    },
  ]

  const exportDataToXLSX = () => {
    exportToXLSX(cols.filter((col) => !col.removeFromExport), caseList, 'Vos dossiers')
  }

  const onEraseFilterClick = () => {
    formikForm.setValues({
      ...formikForm.values,
      Compagnie: '',
      Agence: '',
      NatureSinistre: '',
    })
  }

  const onStatusFilterClick = (status: string) => {
    if (formikForm.values.CodesEtatsDossier?.includes(status)) {
      formikForm.setFieldValue('CodesEtatsDossier', formikForm.values.CodesEtatsDossier.filter((stat) => stat !== status))
    } else {
      formikForm.setFieldValue('CodesEtatsDossier', [ ...formikForm.values.CodesEtatsDossier ?? [], status ])
    }
  }

  const handleSort = (property: DataName) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setCaseList(caseList.slice().sort(getRowsComparator(isAsc ? 'desc' : 'asc', property)))
    setOpenedMenu({ ...openedMenu, sort: false })
  }

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Vos dossiers
        <LongButton
          variant="contained"
          onClick={exportDataToXLSX}
        >
          Exporter
        </LongButton>
      </LargeTitle>
      <FilterDesktopContainer>
        <MyCasesFilters
          companyList={companyList}
          isFetchingCompanyList={isFetchingCompanyList}
          disasterNatureList={disasterNatureList}
          isFetchingDisasterNatureList={isFetchingDisasterNatureList}
          agencyList={agencyList}
          isFetchingAgencyList={isFetchingAgencyList}
          periodList={periodList}
          onEraseFilterClick={onEraseFilterClick}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, filter: !openedMenu.filter })}>
          Filtres
          <DropDownArrow open={openedMenu.filter} />
        </FormTitleWithArrow>
        <Collapse
          in={openedMenu.filter}
          timeout="auto"
          unmountOnExit
        >
          <MyCasesFilters
            size="small"
            companyList={companyList}
            isFetchingCompanyList={isFetchingCompanyList}
            disasterNatureList={disasterNatureList}
            isFetchingDisasterNatureList={isFetchingDisasterNatureList}
            agencyList={agencyList}
            isFetchingAgencyList={isFetchingAgencyList}
            periodList={periodList}
            onEraseFilterClick={onEraseFilterClick}
          />
        </Collapse>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, sort: !openedMenu.sort })}>
          Trier par
          <DropDownArrow open={openedMenu.sort} />
        </FormTitleWithArrow>
        <Collapse
          in={openedMenu.sort}
          timeout="auto"
          unmountOnExit
        >
          {
            cols.map((col) => (
              <CheckableSortingButton
                key={col.id}
                checked={orderBy === col.id}
                label={col.label}
                onChange={handleSort(col.sortId ?? col.id)}
                order={order}
              />
            ))
          }
        </Collapse>
        <BoldSeparator />
      </FilterMobileContainer>
      <FormBoldTitle>
        Statuts
      </FormBoldTitle>
      <ScrollableFiltersContainer>
        {
          statusFilters.map((filter) => (
            <Chip
              variant={formikForm.values.CodesEtatsDossier?.includes(filter.status.code) ? 'contained' : 'outlined'}
              key={filter.status.code}
              onClick={() => onStatusFilterClick(filter.status.code)}
            >
              {filter.status.libelle}
              <b>
                {filter.amount}
              </b>
            </Chip>
          ))
        }
      </ScrollableFiltersContainer>
      <LoadingOverlay isLoading={isFetchingCaseResultList}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={caseList}
              setRows={(rows) => setCaseList(rows as MesDossierResultat[])}
              colHeaders={cols}
              onRowClick={(row) => handleNavigate((row as MesDossierResultat).id)}
              limit={RESULT_LIMIT}
              pagination={
                {
                  setServerLimit: (limit) => formikForm.setFieldValue('Limit', limit),
                  setServerStartIndex: (startIndex) => formikForm.setFieldValue('StartIndex', startIndex),
                  totalRows,
                }
              }
              sorting={{ setOrder, setOrderBy }}
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          {
            caseList.map((myCase, index) => (
              <MyCasesMobileCard
                key={`${myCase.id}-${index}`}
                myCase={myCase}
                isFetchingDocumentsInfo={isFetchingDocumentInfosList}
                documentsInfo={documentInfosList.find((docInfos) => docInfos.dossier === myCase.id)}
                handleNavigate={() => handleNavigate(myCase.id)}
              />
            ))
          }
        </MobileCardContainer>
      </LoadingOverlay>
    </Form>
  )
}

export default MyCasesPage
