/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { useAppDispatch } from 'store/hooks'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'
import {
  useGetAgencyListQuery,
  useGetCompanyListQuery,
  useGetDisasterNatureListQuery,
  useGetNotificationListQuery,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { getCaseStatusColor } from 'helpers/caseStatusColor'
import { getNotificationStatusColor } from 'helpers/notificationStatusColor'
import { getRowsComparator } from 'helpers/tableUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Collapse,
} from '@mui/material'
import {
  CircleRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import RouteTitle from 'router/RouteTitle'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import BlueRoundedChip from 'components/RoundedChip/RoundedChip'
import Table from 'components/Table/Table'
import NotificationsFilters from './NotificationsComponents/NotificationsFilters'
import NotificationsTimelineCard from './NotificationsComponents/NotificationsTimelineCard'
import NotificationsTableMobileCard from './NotificationsComponents/NotificationsTableMobileCard'
import NotificationsViewedButton from './NotificationsComponents/NotificationsViewedButton'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  CodeLabel,
  NotificationUtilisateur,
  TypeNotificationUtilisateurEnumLabel,
} from 'API/__generated__/Api'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'types/Table'

/* Type declarations -------------------------------------------------------- */
const notificationsSchema = Yup.object({
  filters: Yup.object({
    compagnie: Yup.string(),
    natureSinistre: Yup.string(),
    agence: Yup.string(),
    status: Yup.array(Yup.string()).required(),
  }).required(),
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type NotificationsSchema = Yup.InferType<typeof notificationsSchema>

type NotificationsForm = FormikContextType<NotificationsSchema>

type NotificationGroup = {[x: string]: NotificationUtilisateur[]}

/* Internal variables ------------------------------------------------------- */
const RESULT_LIMIT = 10

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 75% 24%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

interface GroupContainerProps {
  last: boolean;
}

const GroupContainer = styled.div<GroupContainerProps>`
  border-left: 2px solid ${(props) => props.theme.colors.grey};
  padding-left: 20px;
  padding-bottom: ${(props) => props.last ? '0px' : '20px'};
  margin-left: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: 20px;
    border: none;
    padding-left: 0px;
    margin-left: 0px;
  }
`

const DotContainer = styled.div`
  margin-left: -31px;
  margin-right: 13px;
  margin-top: 2px;
`

const DateContainer = styled.div`
  display: flex;
  position: relative;
  top: -3px;
  align-items: start;
`

interface CardContainerProps {
  last: boolean;
}

const CardContainer = styled.div<CardContainerProps>`
  margin-bottom: ${(props) => props.last ? '0px' : '10px'};
`

const Chip = styled(BlueRoundedChip)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const ChipContainer = styled.div`
  margin-top: -10px;
  margin-bottom: 30px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const StatusChipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .status-chip {
    font-size: .8rem;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const Link = styled.a`
  color: ${(props) => props.theme.palette.primary.main};
`

/* Component declaration ---------------------------------------------------- */
interface NotificationsPageProps {}

const NotificationsPage: React.FC<NotificationsPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [ orderedNotificationList, setOrderedNotificationList ] = useState<NotificationUtilisateur[]>([])
  const [ filteredNotificationList, setFilteredNotificationList ] = useState<NotificationUtilisateur[]>([])
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)
  const [ visualMode, setVisualMode ] = useState<'table' | 'timeline' | undefined>('table')

  const {
    currentData: notificationList = [],
    isFetching: isFetchingNotificationList,
  } = useGetNotificationListQuery()
  const {
    currentData: companyList = [],
    isFetching: isFetchingCompanyList,
  } = useGetCompanyListQuery()
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterNatureListQuery()
  const {
    currentData: agencyList = [],
    isFetching: isFetchingAgencyList,
  } = useGetAgencyListQuery({ cabinet: '' })

  const formikForm: NotificationsForm = useForm<NotificationsSchema>(
    {
      initialValues: {
        filters: {
          agence: '',
          compagnie: '',
          natureSinistre: '',
          status: [],
        },
        sort: {
          order: 'desc',
          orderBy: 'date',
        },
      },
      validationSchema: notificationsSchema,
    },
  )

  const getStatusFilters = () => {
    // Find every unique status from notification
    const filterArray = [ ...new Map(notificationList.map((notif) => [ notif.type.code, notif.type ])).values() ]

    // Return the status with amount of elements containing it
    return filterArray.map((status) => ({
      status: status,
      amount: notificationList.filter((notif) => notif.type.code === status.code).length,
    }))
  }

  const filterNotifications = (newList: NotificationUtilisateur[]) => {
    const { status, agence, compagnie, natureSinistre } = formikForm.values.filters
    if (status.length > 0) {
      newList = newList.filter((notif) => status.includes(notif.type.code)) ?? []
    }
    setFilteredNotificationList(newList.filter((notif) =>
      (agence === '' || notif.infoDossier.agenceTraitante === agence) &&
      (compagnie === '' || notif.infoDossier.compagnie?.code === compagnie) &&
      (natureSinistre === '' || notif.infoDossier.sinistre.nature?.code === natureSinistre),
    ))
  }

  useEffect(() => {
    if (!isFetchingNotificationList) {
      const { order, orderBy } = formikForm.values.sort
      const newList = [ ...notificationList ].sort(getRowsComparator(order, orderBy))
      setOrderedNotificationList(newList)
      filterNotifications(newList)
    }
  }, [ isFetchingNotificationList ])

  useEffect(() => {
    if (orderedNotificationList.length > 0) {
      filterNotifications([ ...orderedNotificationList ])
    }
  }, [ formikForm.values.filters ])

  useEffect(() => {
    if (orderedNotificationList.length > 0) {
      const { order, orderBy } = formikForm.values.sort
      setOrderedNotificationList(orderedNotificationList.sort(getRowsComparator(order, orderBy)))
    }
  }, [ formikForm.values.sort ])

  const sortNotification = () => {
    const sortedNotifications: NotificationUtilisateur[] = [ ...[ ...filteredNotificationList ?? [] ].sort((a, b) => (a.date || '').localeCompare(b.date || '')) ]
    const groups: NotificationGroup = {}

    if (filteredNotificationList === null) {
      return groups
    }

    const sortNotificationToGroup = (date: string): void => {
      if (!sortedNotifications.length) return
      if (!groups[date]?.length) groups[date] = []
      if (DateUtils.APIStrToLocalDateString(date) === DateUtils.APIStrToLocalDateString(sortedNotifications[0]?.date)) {
        const toAdd = sortedNotifications.shift()
        toAdd && groups[date].push(toAdd)
        sortNotificationToGroup(date)
      }
      else {
        sortNotificationToGroup(sortedNotifications[0].date || '')
      }
    }

    sortNotificationToGroup(sortedNotifications[0]?.date || '')
    return groups
  }

  const onEraseFilterClick = () => {
    formikForm.setValues({
      ...formikForm.values,
      filters: {
        ...formikForm.values.filters,
        agence: '',
        compagnie: '',
        natureSinistre: '',
      },
    })
  }

  const onStatusFilterClick = (status: string) => {
    if (formikForm.values.filters.status.includes(status)) {
      formikForm.setFieldValue('filters.status', formikForm.values.filters.status.filter((stat) => stat !== status))
    } else {
      formikForm.setFieldValue('filters.status', [ ...formikForm.values.filters.status, status ])
    }
  }

  const setOrder = (order: Order) => formikForm.setFieldValue('sort.order', order)

  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('sort.orderBy', orderBy)

  const handleNavigate = (caseId: string) => {
    dispatch(setRouterLastPath(location.pathname))
    navigate(`/dossiers/${caseId}`)
  }

  const cols: ColumnHeader[] = [
    {
      id: 'type',
      label: 'Évènement',
      sortId: 'type.code',
      render: (status: TypeNotificationUtilisateurEnumLabel) => (
        <StatusChipContainer>
          <ColoredSquareChip
            color={getNotificationStatusColor(status.code)}
            className="status-chip"
          >
            {status.libelle}
          </ColoredSquareChip>
        </StatusChipContainer>
      ),
    },
    {
      id: 'date',
      label: "Date de l'évènement",
      render: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit' }),
    },
    {
      id: 'infoDossier.sinistre.referenceCompagnie',
      label: 'Ref compagnie',
      render: (companyRef: string, row) => (
        <Link
          href={`/dossiers/${(row as NotificationUtilisateur).infoDossier.id}`}
          onClick={(e) => {e.stopPropagation(); handleNavigate((row as NotificationUtilisateur).infoDossier.id)}}
        >
          {companyRef}
        </Link>
      ),
    },
    {
      id: 'infoDossier.assure.nom',
      label: 'Assuré',
    },
    {
      id: 'infoDossier.assure.police',
      label: 'N° de Contrat',
    },
    {
      id: 'infoDossier.sinistre.nature.libelle',
      label: 'Type de sinistre',
    },
    {
      id: 'infoDossier.etat',
      label: 'Statut',
      sortId: 'infoDossier.etat.code',
      render: (status: CodeLabel) => (
        <StatusChipContainer>
          <ColoredSquareChip
            color={getCaseStatusColor(status.code)}
            className="status-chip"
          >
            {status.libelle}
          </ColoredSquareChip>
        </StatusChipContainer>
      ),
    },
    {
      id: 'id',
      label: '',
      render: (id: string) => (
        <NotificationsViewedButton id={id} />
      ),
    },
  ]

  const statusFilters = useMemo(() => getStatusFilters(), [ orderedNotificationList ])

  const sortedNotifications = useMemo(() => sortNotification(), [ filteredNotificationList ])

  return (
    <Form form={formikForm}>
      <RouteTitle title="Notifications" />
      <LargeTitle>
        Notifications
      </LargeTitle>
      <FilterDesktopContainer>
        <NotificationsFilters
          companyList={companyList}
          isFetchingCompanyList={isFetchingCompanyList}
          disasterNatureList={disasterNatureList}
          isFetchingDisasterNatureList={isFetchingDisasterNatureList}
          agencyList={agencyList}
          isFetchingAgencyList={isFetchingAgencyList}
          onEraseFilterClick={onEraseFilterClick}
          visualMode={visualMode}
          onChangeVisualMode={setVisualMode}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
          Filtres
          <DropDownArrow open={openedFilterMenu} />
        </FormTitleWithArrow>
        <Collapse
          in={openedFilterMenu}
          timeout="auto"
          unmountOnExit
        >
          <NotificationsFilters
            size="small"
            companyList={companyList}
            isFetchingCompanyList={isFetchingCompanyList}
            disasterNatureList={disasterNatureList}
            isFetchingDisasterNatureList={isFetchingDisasterNatureList}
            agencyList={agencyList}
            isFetchingAgencyList={isFetchingAgencyList}
            onEraseFilterClick={onEraseFilterClick}
            visualMode={visualMode}
            onChangeVisualMode={setVisualMode}
          />
        </Collapse>
        <BoldSeparatorMargin />
      </FilterMobileContainer>
      <FormBoldTitle>
        Statuts
      </FormBoldTitle>
      <ChipContainer>
        <ScrollableFiltersContainer>
          {
            statusFilters.map((filter) => (
              <Chip
                variant={formikForm.values.filters.status.includes(filter.status.code) ? 'contained' : 'outlined'}
                key={filter.status.code}
                onClick={() => onStatusFilterClick(filter.status.code)}
              >
                {filter.status.libelle}
                <b>
                  {filter.amount}
                </b>
              </Chip>
            ))
          }
        </ScrollableFiltersContainer>
      </ChipContainer>
      {
        visualMode === 'timeline' &&
          <>
            {
              Object.entries(sortedNotifications)
                .sort((a, b) => b[0].localeCompare(a[0]))
                .map(([ date, notifList ], index) => (
                  <GroupContainer
                    key={`${date}-${index}`}
                    last={index === Object.entries(sortedNotifications).length - 1}
                  >
                    <DateContainer>
                      <DotContainer>
                        <CircleRounded
                          color="primary"
                          fontSize="small"
                        />
                      </DotContainer>
                      {DateUtils.APIStrToLocalDateString(date)}
                    </DateContainer>
                    {
                      notifList.map((notif, notifIndex) => (
                        <CardContainer
                          key={`${notif.id}-${index}-${notifIndex}`}
                          last={notifIndex === notifList.length - 1}
                        >
                          <NotificationsTimelineCard
                            notification={notif}
                            handleNavigate={() => handleNavigate(notif.infoDossier.id)}
                          />
                        </CardContainer>
                      ))
                    }
                  </GroupContainer>
                ))
            }
          </>
      }
      {
        visualMode === 'table' &&
          <div>
            <TableCardContainer>
              <TableCardContentContainer>
                <Table
                  rows={filteredNotificationList}
                  setRows={(rows) => setFilteredNotificationList(rows as NotificationUtilisateur[])}
                  colHeaders={cols}
                  defaultOrder={{ order: 'desc', orderBy: 'date' }}
                  onRowClick={(row) => handleNavigate((row as NotificationUtilisateur).infoDossier.id)}
                  limit={RESULT_LIMIT}
                  sorting={{ setOrder, setOrderBy }}
                />
              </TableCardContentContainer>
            </TableCardContainer>
            <MobileCardContainer>
              {
                filteredNotificationList.map((notif, index) => (
                  <NotificationsTableMobileCard
                    key={`${notif.id}-${index}`}
                    notification={notif}
                    handleNavigate={() => handleNavigate(notif.infoDossier.id)}
                  />
                ))
              }
            </MobileCardContainer>
          </div>
      }
    </Form>
  )
}

export default NotificationsPage
